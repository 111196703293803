<template>
    <div class="contact">
        <section class="bgimage d-flex justify-content-center align-items-center text-white text-center">
            <b-container>
                <b-row>
                    <b-col>
                        <h1>Contact</h1>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <section class="section-1 d-flex justify-content-center align-items-center py-5">
            <b-img v-parallax="0.2" class="d-none d-lg-block" src="@/assets/japanese-flowers.png" fluid
                   alt="Responsive image"
            />
            <b-container>
                <b-row>
                    <b-col sm="12" lg="10" offset-lg="1" class="p-5">
                        <div class="section-title">
                            <h1>Contact</h1>
                        </div>
                        <p>
                          Wij verwelkomen u dagelijks van 12:00 tot 22:00 uur. U kunt al vanaf 12:00 telefonisch of online bestellen, ook kunt u terecht voor ons lunch All-You-Can-Eat arrangement.
                        </p>
                        <p>
                            Telefoonnummer:<br>
                            <b>0180-418 414</b>.
                        </p>
                        <p>
                            Adres: <br>
                            <b>Groen van Prinstererweg 19-21<br>
                                2982VA Ridderkerk</b>
                        </p>
<!--                        <p>-->
<!--                            Wij proberen u zoveel mogelijk van dienst te zijn.-->
<!--                            Wij hopen dat wij snel weer op de oude, gastvrije manier u te mogen ontvangen.-->
<!--                            Veel gezondheid toegewenst.-->
<!--                        </p>-->
                        <b-button
                                href="tel:0180 418 414"
                                pill
                                variant="danger"
                                class="text-uppercase font-weight-bold mt-3"
                        >
                            Bel om te reserveren
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'Contact',
    }
</script>

<style scoped>
    .contact .bgimage {
        background: url('../assets/header/banner-street.jpg');
        background-size: cover;
    }
</style>